<template>
	<v-layout row :style="heightComputed" wrap>
		<v-list two-line style="width: 100%">
			<v-list-tile v-if="!readonly && types">
				<v-layout>
					<v-menu bottom offset-x right>
						<template v-slot:activator="{ on }">
							<v-btn color="primary" flat v-on="on">{{ $t('actions.add') }}</v-btn>
						</template>
						<v-list>
							<v-list-tile @click="addTask()"> <v-list-tile-title v-t="'offers.a_task'" /></v-list-tile>
							<v-list-tile @click="addTask(true)"><v-list-tile-title v-t="'offers.an_information_request'" /></v-list-tile>
						</v-list>
					</v-menu>
				</v-layout>
			</v-list-tile>
			<v-list-tile v-for="(task, index) in tasks" :key="index">
				<v-layout align-center>
					<v-flex grow pr-1>
						<v-text-field
							v-if="readonly"
							v-model="task.name"
							class="task_model"
							full-width
							:hint="createHint(task)"
							:label="$tc(label, 1, {index: index + 1})"
							persistent-hint
							:prepend-icon="getTaskIcon(task)"
							readonly
						/>
						<v-text-field
							v-else
							v-model="task.name"
							:label="$tc(label, 1, {index: index + 1})"
							class="task_model"
							style="width: 100%"
							maxlength="191"
							:rules="[emptyName(task.name), isADoublon(task.name, index)]"
							:prepend-icon="getTaskIcon(task)"
							@blur="onBlur(task.name, index)"
							@input="$emit('input', tasks)"
						/>
					</v-flex>
					<v-flex v-if="readonly" shrink>
						<v-text-field v-model="task.displayed_duration" full-width hide-details :label="$t('offers.duration')" readonly />
					</v-flex>
					<VDuration v-else v-model="task.duration" />
					<v-flex v-if="!readonly" shrink>
						<w-btn :disabled="tasks.length <= 1" flat icon="account_tree" mini @click="openDependenciesManager(index)">
							{{ $t('offers.manage_dependencies') }}
						</w-btn>
						<w-btn-delete :disabled="tasks.length <= 1" mini @click="removeTask(index)" />
					</v-flex>
				</v-layout>
			</v-list-tile>
		</v-list>
		<v-dialog v-model="dialog" max-width="700" persistent scrollable>
			<v-card>
				<v-card-title :class="$vuetify.breakpoint.mdAndUp ? 'secondary' : 'pl-2 pr-0 py-0 secondary'">
					<v-layout row wrap align-center justify-space-around>
						<v-flex v-t="'offers.manage_dependencies_title'" grow secondary title white--text />
						<v-flex shrink>
							<v-btn icon class="white--text" @click="closeDialog">
								<v-icon>close</v-icon>
							</v-btn>
						</v-flex>
					</v-layout>
				</v-card-title>
				<v-card-text>
					<v-layout row wrap>
						<v-flex
							xs12
							v-text="
								$t('offers.manage_dependencies_description', {
									name: selectedTask.name
								})
							"
						/>
						<v-flex xs12>
							<v-list v-if="parentTasksPossible.length">
								<v-list-tile v-for="(parentTask, index) in parentTasksPossible" :key="index">
									<v-list-tile-action>
										<v-checkbox v-model="parentTasksForCheckboxes[index]" color="primary" @change="changeDependencies(index)" />
									</v-list-tile-action>
									<v-list-tile-content>
										<v-list-tile-title v-text="parentTask.name" />
									</v-list-tile-content>
								</v-list-tile>
							</v-list>
							<v-flex v-else-if="parentTasksPossible.length == 0 && tasks.length > 0" v-t="'offers.errors.loop_in_tasks_dependencies'" xs12 />
						</v-flex>
					</v-layout>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-layout>
</template>

<script>
import OffersModuleGuard from '@/mixins/ModulesGuards/Offers/OffersModuleGuard'

export default {
	name: 'TasksList',
	components: {
		VDuration: () => ({
			component: import('@/components/Commons/VDuration')
		})
	},
	mixins: [OffersModuleGuard],
	props: {
		height: {
			required: false,
			type: String,
			default: '300'
		},
		keyname: {
			required: false,
			type: String,
			default: 'name'
		},
		label: {
			required: false,
			type: String,
			default: 'offers.tasks'
		},
		readonly: {
			required: false,
			type: Boolean,
			default: false
		},
		tasks: {
			required: true,
			type: Array
		}
	},
	data: function () {
		return {
			dialog: false,
			parentTasksForCheckboxes: [],
			selectedIndex: null,
			selectedTask: {},
			types: null
		}
	},
	computed: {
		heightComputed: function () {
			let res = ''
			if (this.height) {
				let heightVal = this.height
				if (!this.height.endsWith('px')) {
					heightVal += 'px'
				}
				res = 'max-height:' + heightVal + 'min-height:' + heightVal + 'overflow-y:auto'
			}
			return res
		},
		parentTasksPossible: function () {
			let result = []
			if (this.selectedIndex != null && this.selectedIndex >= 0) {
				const selectedTask = this.selectedTask
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.parentTasksForCheckboxes = []

				this.tasksClone.forEach((item, index) => {
					if (index != this.selectedIndex && (!item.parents || !this.isRelatedTo(selectedTask.name, item))) {
						result.push(item)
						this.parentTasksForCheckboxes.push(selectedTask.parents && selectedTask.parents.find(o => o.name == item.name))
					}
				})
			}
			return result
		},
		tasksClone: {
			get: function () {
				return this.tasks
			},
			set: function (newValue) {
				this.$emit('update-tasks', newValue)
			}
		}
	},
	created: function () {
		this.service.listTaskTypes().then(types => {
			this.types = types
		})
	},
	methods: {
		addTask: function (isInformationRequest = false) {
			const index = this.tasksClone.length
			let type
			if (isInformationRequest) {
				type = this.types.find(type => type.name === 'information_request')
			} else {
				type = this.types.find(type => type.name === 'accounting_firm_task')
			}
			this.tasksClone.push({ name: '', type: type, task_type_id: type.id })
			this.$nextTick(() => {
				const models = document.querySelectorAll('.task_model input')
				if (models[index + 1]) {
					models[index + 1].focus()
				}
			})
		},
		closeDialog: function () {
			let emittedValue = [...this.tasksClone]
			emittedValue[this.selectedIndex] = { ...this.selectedTask }
			this.$emit('input', emittedValue)
			this.dialog = false
			this.selectedIndex = null
			this.selectedTask = {}
		},
		createHint: function (task) {
			let result = null
			if (task.parents && task.parents.length > 0) {
				if (task.parents.length == 1) {
					result = this.$tc('offers.dependencies', 1, { name: task.parents[0].name })
				} else {
					let list = []
					for (let i = 0; i < task.parents.length - 1; i++) {
						list.push('"' + task.parents[i].name + '"')
					}
					result = this.$tc('offers.dependencies', 2, {
						last_task: task.parents[task.parents.length - 1].name,
						tasks: list.join(', ')
					})
				}
			}
			return result
		},
		changeDependencies: function (index) {
			if (!this.selectedTask.parents && !Array.isArray(this.selectedTasks)) {
				this.selectedTask.parents = []
			}
			if (this.parentTasksForCheckboxes[index]) {
				this.selectedTask.parents.push(this.parentTasksPossible[index])
			} else {
				this.selectedTask.parents = this.selectedTask.parents.filter(item => item.name != this.parentTasksPossible[index].name)
			}
		},
		emptyName: function (taskname = '') {
			return taskname.length > 0 || this.$t('offers.errors.empty_task_name')
		},
		isADoublon: function (taskName, taskIndex) {
			const doublon = this.tasksClone.filter((task, index) => {
				return task.name.trim() == taskName.trim() && index < taskIndex
			})
			return doublon.length == 0 || this.$t('offers.errors.taskname_already_used')
		},
		isRelatedTo: function (needle, task) {
			if (!task.parents || task.parents.length == 0) {
				return false
			} else {
				let res = false
				const parents = this.tasksClone.filter(item => {
					return task.parents.find(o => {
						if (o.id && item.id) {
							return o.id == item.id
						} else {
							return o.name == item.name
						}
					})
				})
				parents.forEach(item => {
					if (item.name == needle || this.isRelatedTo(needle, item)) {
						res = true
					}
				})
				return res
			}
		},
		onBlur: function (taskName, taskIndex) {
			if ((taskName && typeof this.isADoublon(taskName, taskIndex) == 'string') || typeof this.emptyName(taskName) === 'string') {
				this.removeTask(taskIndex)
			}
		},
		openDependenciesManager: function (index) {
			this.selectedIndex = index
			this.selectedTask = { ...this.tasksClone[index] }
			this.dialog = true
		},
		removeTask: function (index) {
			this.tasksClone.splice(index, 1)
		},
		getTaskIcon: function (task) {
			let result = 'help'
			if (task.type && task.type.name == 'information_request') {
				result = 'info_outline'
			} else if (task.type && task.type.name == 'accounting_firm_task') {
				result = 'label_outline'
			}
			return result
		}
	}
}
</script>
